import React from 'react';
import {View, ScrollView} from 'react-native';
import BetPanel from '../bet-panel/bet-panel';
import theme from '@/style';
import DetailNavTitle from '@/components/business/detail-nav-title/detail-nav-title';
import {goBack, goTo, toPriceStr} from '@/utils';
import {useRoute} from '@react-navigation/native';
import {BasicObject} from '@/types';
import {IGameOrder} from '@/common-pages/result/result.type';
import Text from '@/components/basic/text';
import Price from '@/components/business/price/price';
import LazyImage from '@/components/basic/image/lazy-image';
import {DownIcon} from '@/common-pages/casino/svg.variable';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import globalStore from '@/services/global.state';

const ScratchDetail = () => {
  const {params = {}} = useRoute() as BasicObject;
  const {scratchOrder = ''} = params;
  const orderDetail = JSON.parse(scratchOrder) as IGameOrder;

  const goPlay = () => {
    if (!globalStore.token) {
      goTo('Login', {backPage: 'Home'});
      return;
    }
    goTo('Scratch', {
      path: `my-scratch-list/detail/${orderDetail.orderId}`,
      hideInnerTitle: '1',
    });
  };

  return (
    <View style={[theme.flex.flex1, theme.background.lightGrey]}>
      <DetailNavTitle
        onBack={goBack}
        serverRight
        hideAmount
        title={'Details'}
      />
      <NativeTouchableOpacity
        onPress={goPlay}
        style={[
          theme.background.white,
          theme.flex.row,
          theme.flex.centerByCol,
          {padding: theme.paddingSize.l * 2},
        ]}>
        <LazyImage
          radius={8}
          width={48}
          height={48}
          occupancy="transparent"
          imageUrl={orderDetail.gameIcon}
        />
        <View
          style={[
            theme.flex.row,
            theme.flex.between,
            theme.flex.flex1,
            theme.flex.centerByCol,
          ]}>
          <View style={[{gap: theme.paddingSize.xxs}, theme.padding.leftl]}>
            <Text main size="medium" fontFamily="fontInterBold">
              {orderDetail.gameName}
            </Text>
            <Text main fontFamily="fontInterBold">
              {toPriceStr(orderDetail.orderPrice)} x {orderDetail.orderNumber}
            </Text>
          </View>
          <View
            style={[
              {
                transform: [
                  {
                    rotate: '-90deg',
                  },
                ],
              },
            ]}>
            <DownIcon />
          </View>
        </View>
      </NativeTouchableOpacity>
      <View style={[theme.flex.flex1, theme.flex.basis0]}>
        <ScrollView contentContainerStyle={[theme.padding.topl]}>
          <BetPanel id={orderDetail.orderId} betTime={orderDetail.orderTime}>
            {orderDetail.orderList?.map((item, _i) => {
              const backgroundColor = _i % 2 === 0 ? '#fff' : '#F8F9FF';
              return (
                <View
                  key={_i}
                  style={[
                    theme.padding.lrl,
                    theme.padding.tbs,
                    theme.flex.row,
                    theme.flex.centerByCol,
                    {backgroundColor},
                  ]}>
                  <View style={[theme.flex.flex1]}>
                    <Text size="medium" main>
                      ID:{item.cardId}
                    </Text>
                  </View>
                  <View
                    // eslint-disable-next-line react-native/no-inline-styles
                    style={[{width: 96}, theme.flex.centerByCol]}>
                    <Price
                      textProps={{textAlign: 'center', size: 'medium'}}
                      price={orderDetail.orderPrice}
                    />
                  </View>
                  <View
                    // eslint-disable-next-line react-native/no-inline-styles
                    style={[theme.flex.alignEnd, {width: 96}]}>
                    <Text>Won</Text>
                    <Price
                      textProps={{
                        textAlign: 'center',
                        size: 'medium',
                        color: item.winAmount ? '#F15802' : '#000',
                      }}
                      price={item.winAmount}
                    />
                  </View>
                </View>
              );
            })}
          </BetPanel>
        </ScrollView>
      </View>
    </View>
  );
};

export default ScratchDetail;
